import * as React from "react";
const SvgContactSupport = (props) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", width: "16px", height: "16px", viewBox: "0 0 16 16", ...props }, /* @__PURE__ */ React.createElement("path", { style: {
  stroke: "none",
  fillRule: "nonzero",
  fill: "rgb(0%,0%,0%)",
  fillOpacity: 1
}, d: "M 8 9.5 C 6.828125 9.5 5.730469 8.914062 5.082031 7.9375 L 5.917969 7.382812 C 6.378906 8.082031 7.160156 8.5 8 8.5 C 8.839844 8.5 9.621094 8.082031 10.082031 7.382812 L 10.917969 7.9375 C 10.269531 8.914062 9.171875 9.5 8 9.5 Z M 8 9.5 " }), /* @__PURE__ */ React.createElement("path", { style: {
  stroke: "none",
  fillRule: "nonzero",
  fill: "rgb(0%,0%,0%)",
  fillOpacity: 1
}, d: "M 10 4 C 9.449219 4 9 4.449219 9 5 C 9 5.550781 9.449219 6 10 6 C 10.550781 6 11 5.550781 11 5 C 11.003906 4.734375 10.898438 4.476562 10.710938 4.289062 C 10.523438 4.101562 10.265625 3.996094 10 4 Z M 10 4 " }), /* @__PURE__ */ React.createElement("path", { style: {
  stroke: "none",
  fillRule: "nonzero",
  fill: "rgb(0%,0%,0%)",
  fillOpacity: 1
}, d: "M 6 4 C 5.449219 4 5 4.449219 5 5 C 5 5.550781 5.449219 6 6 6 C 6.550781 6 7 5.550781 7 5 C 7.003906 4.734375 6.898438 4.476562 6.710938 4.289062 C 6.523438 4.101562 6.265625 3.996094 6 4 Z M 6 4 " }), /* @__PURE__ */ React.createElement("path", { style: {
  stroke: "none",
  fillRule: "nonzero",
  fill: "rgb(0%,0%,0%)",
  fillOpacity: 1
}, d: "M 8.867188 15 L 8 14.5 L 10 11 L 13 11 C 13.265625 11 13.519531 10.894531 13.707031 10.707031 C 13.894531 10.519531 14 10.265625 14 10 L 14 3 C 14 2.734375 13.894531 2.480469 13.707031 2.292969 C 13.519531 2.105469 13.265625 2 13 2 L 3 2 C 2.734375 2 2.480469 2.105469 2.292969 2.292969 C 2.105469 2.480469 2 2.734375 2 3 L 2 10 C 2 10.265625 2.105469 10.519531 2.292969 10.707031 C 2.480469 10.894531 2.734375 11 3 11 L 7.5 11 L 7.5 12 L 3 12 C 1.894531 12 1 11.105469 1 10 L 1 3 C 1 1.894531 1.894531 1 3 1 L 13 1 C 14.105469 1 15 1.894531 15 3 L 15 10 C 15 11.105469 14.105469 12 13 12 L 10.582031 12 Z M 8.867188 15 " }));
export default SvgContactSupport;
